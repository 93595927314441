import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import axios from 'common/axios';
import { mediaQueries } from 'common/theme';
import {
    PRIVILEGES,
    AUTHORIZATION,
    TERRITORIES_STATES,
} from 'common/constants';
import { Icons } from 'common/Icons';
import { Box, Row } from './BasicComponents';
import VersionRow from './VersionRow';

const resetScroll = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
};

const Menu = ({ auth, inSideDrawer }) => {
    const { t } = useTranslation();
    const [applicationWarning, setApplicationWarning] = useState(false);
    const [usersWarning, setUsersWarning] = useState(false);
    const [territoryWarning, setTerritoryWarning] = useState(false);

    const isLoggedIn = !!auth?.token;
    const isApproved = !!(
        isLoggedIn && auth.authorization?.includes(AUTHORIZATION.APPROVED)
    );
    const isPublisher = !!(
        isApproved && auth?.privileges.includes(PRIVILEGES.PUBLISHER)
    );

    const cartsApproved = !!auth?.privileges.includes(
        PRIVILEGES.CARTS_APPROVED
    );

    const canEditTerritories = !!(
        isPublisher && auth.authorization?.includes(AUTHORIZATION.TERRITORIES)
    );

    const isMinistryServant = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.MINISTRY_SERVANT)
    );
    const isElder = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.ELDER)
    );
    const isGroupOverseer = !!auth.privileges.includes(
        PRIVILEGES.GROUP_OVERSEER
    );
    const isGroupAssistant = !!auth.privileges.includes(
        PRIVILEGES.GROUP_ASSISTANT
    );
    const isServiceOverseer = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.SERVICE_OVERSEER)
    );
    const isSecretary = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.SECRETARY)
    );
    const isCoordinator = !!(
        isPublisher && auth.privileges.includes(PRIVILEGES.COORDINATOR)
    );
    const isCommittee = !!(isServiceOverseer || isSecretary || isCoordinator);

    const canViewCGReport =
        isSecretary ||
        (isElder && auth.authorization.includes(AUTHORIZATION.VIEW_REPORT));

    const canViewGroupReportMinistryServant =
        isMinistryServant && (isGroupOverseer || isGroupAssistant);

    const canViewGroupReportElder =
        isElder && (isGroupOverseer || isGroupAssistant);

    const canViewTerritories = auth.authorization.includes(
        AUTHORIZATION.TERRITORIES
    );

    const showCounts =
        isPublisher &&
        (auth.authorization?.includes(AUTHORIZATION.COUNTS_SEND) ||
            auth.authorization?.includes(AUTHORIZATION.COUNTS_HISTORY) ||
            isMinistryServant ||
            isElder);

    const showLiterature =
        isPublisher &&
        (auth.authorization?.includes('literature') ||
            isMinistryServant ||
            isElder);
    const canEditLiterature =
        isPublisher &&
        (auth.authorization?.includes('literature') || isGroupOverseer);
    const showMaintenance = isPublisher && (isMinistryServant || isElder);
    const canEditMaintenance = isPublisher && isElder;
    const showCGTasksSection = showCounts || showLiterature || showMaintenance;

    const loadApplications = useCallback(
        (token) => {
            const newApplications = [];
            axios
                .get(`applications.json?auth=${token}`)
                .then((response) => {
                    if (!response.data) {
                        return;
                    } else {
                        Object.keys(response.data).forEach((id) => {
                            if (
                                !response.data[id].serviceApproved ||
                                !response.data[id].secretaryApproved ||
                                !response.data[id].coordinatorApproved
                            ) {
                                newApplications.push(1);
                            }
                        });
                        if (newApplications.length) {
                            setApplicationWarning(true);
                        } else {
                            setApplicationWarning(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        [setApplicationWarning]
    );

    const loadUsers = useCallback((token) => {
        axios
            .get(`users.json?auth=${token}`)
            .then((response) => {
                const newUsers = [];
                if (!response?.data) {
                    return;
                } else {
                    Object.keys(response.data).forEach((id) => {
                        if (!response.data[id]?.authorization?.approved) {
                            newUsers.push(1);
                        }
                    });
                    if (newUsers.length) {
                        setUsersWarning(true);
                    } else {
                        setUsersWarning(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const loadTerritories = useCallback((token) => {
        axios
            .get(`territories.json?auth=${token}`)
            .then((response) => {
                if (!response?.data) {
                    return;
                } else {
                    if (
                        Object.keys(response.data).some(
                            (id) =>
                                response.data[id]?.state ===
                                    TERRITORIES_STATES.SUBMITTED ||
                                response.data[id]?.state ===
                                    TERRITORIES_STATES.PICKED
                        )
                    ) {
                        setTerritoryWarning(true);
                    } else {
                        setTerritoryWarning(false);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (isCommittee) {
            loadApplications(auth.token);
        }
        if (
            auth.authorization?.includes(AUTHORIZATION.EDIT_USERS) ||
            isCoordinator
        ) {
            loadUsers(auth.token);
        }
        if (canViewTerritories) {
            loadTerritories(auth.token);
        }
    }, [
        auth.token,
        isCommittee,
        loadApplications,
        loadUsers,
        auth.authorization,
        isCoordinator,
        canViewTerritories,
        loadTerritories,
    ]);

    const menuSections = [
        {
            sectionName: 'approvedSection',
            showSection: isApproved,
            menuItems: [
                {
                    linkTo: '/schedule',
                    icon: 'calendar',
                    text: t('ZHROMAŽDENIA'),
                    showEditLink:
                        isPublisher &&
                        auth.authorization?.includes('editSchedule'),
                },
                {
                    linkTo: '/serviceMeetings',
                    icon: 'bag',
                    text: t('SCHÔDZKY'),
                    showEditLink:
                        isPublisher &&
                        auth.authorization?.includes('editServiceMeetings'),
                },
                {
                    linkTo: '/talks',
                    icon: 'speaker',
                    text: t('PREDNÁŠKY'),
                    showEditLink:
                        isPublisher &&
                        (auth.authorization?.includes('editTalks') ||
                            isMinistryServant ||
                            isElder),
                },
                {
                    linkTo: '/announcements',
                    icon: 'announcement',
                    text: t('OZNAMY'),
                    showEditLink:
                        isPublisher &&
                        auth.authorization?.includes('editAnnouncements'),
                },
                {
                    linkTo: '/cooperation',
                    icon: 'cooperation',
                    text: t('SPOLUPRÁCA S KS'),
                    showEditLink:
                        isPublisher &&
                        auth.authorization?.includes('editCOSchedule'),
                },
                {
                    linkTo: '/memorial',
                    icon: 'wine',
                    text: t('PAMÄTNÁ SLÁVNOSŤ'),
                    showEditLink:
                        isPublisher &&
                        auth.authorization?.includes('editMemorial'),
                },
            ],
        },
        {
            sectionName: 'publisherSection',
            showSection: isPublisher,
            sectionHeading: t('Zvestovateľ'),
            icon: 'user',
            menuItems: [
                {
                    linkTo: '/myAccount/myReport',
                    icon: 'envelope',
                    text: t('MOJA SPRÁVA'),
                },
                {
                    linkTo: '/myAccount/myTerritories',
                    icon: 'map',
                    text: t('MOJE OBVODY'),
                    showEditLink: canEditTerritories,
                    showWarning: territoryWarning,
                },
                {
                    linkTo: '/myAccount/applications',
                    icon: 'application',
                    text: t('MOJE PRIHLÁŠKY'),
                },
                {
                    linkTo: '/myAccount/myLiterature',
                    icon: 'book',
                    text: t('MOJA LITERATÚRA'),
                },
                {
                    linkTo: '/groups',
                    icon: 'group',
                    text: t('ZVESTOVATEĽSKÉ SKUPINY'),
                },
                {
                    linkTo: '/congregation',
                    icon: 'house',
                    text: t('MÔJ ZBOR'),
                },
                {
                    linkTo: '/myAccount/settings',
                    icon: 'usersettings',
                    text: t('NASTAVENIA ÚČTU'),
                },
                {
                    linkTo: '/tutorials',
                    icon: 'help',
                    text: t('NÁVODY'),
                },
                {
                    linkTo: '/emergency-plan',
                    icon: 'emergency',
                    text: t('KRÍZOVÝ PLÁN'),
                },
                // {
                //     linkTo: '/myAccount/reportBug',
                //     icon: 'warning',
                //     text: t('NAHLÁSIŤ CHYBU/NÁPAD'),
                // },
            ],
        },

        {
            sectionName: 'cgTasks',
            showSection: showCGTasksSection,
            sectionHeading: t('Úlohy v zbore'),
            icon: 'speaker',
            menuItems: [
                {
                    linkTo: '/counts',
                    icon: 'calculator',
                    text: t('POČTY PRÍTOMNÝCH'),
                    disabled: !showCounts,
                },
                {
                    linkTo: '/elders/literature',
                    icon: 'book',
                    text: t('ZBOROVÁ LITERATÚRA'),
                    disabled: !showLiterature,
                    showEditLink: canEditLiterature,
                },
                {
                    linkTo: '/elders/maintenanceReports',
                    icon: 'settings',
                    text: t('ÚDRŽBA'),
                    disabled: !showMaintenance,
                    showEditLink: canEditMaintenance,
                },
            ],
        },
        {
            sectionName: 'appointed',
            showSection: isMinistryServant || isElder,
            sectionHeading: t('Menovaný'),
            icon: 'speaker',
            menuItems: [
                {
                    linkTo: '/elders/visiting',
                    icon: 'speaker',
                    text: t('HOSŤOVANIE'),
                },
                {
                    linkTo: '/elders/groupReport',
                    icon: 'envelope',
                    text: t('SPRÁVA ZA SKUPINU'),
                    disabled: !canViewGroupReportMinistryServant,
                },
            ],
        },
        {
            sectionName: 'elder',
            showSection: isElder,
            sectionHeading: t('Starší'),
            icon: 'speaker',
            menuItems: [
                {
                    linkTo: '/elders/groupReport',
                    icon: 'envelope',
                    text: t('SPRÁVA ZA SKUPINU'),
                    disabled: !canViewGroupReportElder,
                },
                {
                    linkTo: '/elders/applicationsAll',
                    icon: 'application',
                    text: t('VŠETKY PRIHLÁŠKY'),
                    disabled: !((isElder && isGroupOverseer) || isCommittee),
                    showWarning: applicationWarning,
                },
                {
                    linkTo: '/elders/cartsPublishers',
                    icon: 'travel',
                    text: t('SCHVÁLENÍ NA STOJANY'),
                    showEditLink: isCommittee,
                },
                {
                    linkTo: '/elders/notes',
                    icon: 'announcement',
                    text: t('POZNÁMKY'),
                },
            ],
        },
        {
            sectionName: 'secretary',
            showSection: canViewCGReport,
            sectionHeading: t('Tajomník'),
            icon: 'speaker',
            menuItems: [
                {
                    linkTo: '/elders/cgReport',
                    icon: 'envelope',
                    text: t('ZBOROVÁ SPRÁVA'),
                },
            ],
        },

        {
            sectionName: 'administrator',
            showSection:
                isCoordinator ||
                (isPublisher &&
                    (auth.authorization?.includes('editPublishers') ||
                        auth.authorization?.includes('editUsers') ||
                        isCommittee)),
            sectionHeading: t('Správca'),
            icon: 'speaker',
            menuItems: [
                {
                    linkTo: '/elders/users',
                    icon: 'users',
                    text: t('POUŽÍVATELIA'),
                    showWarning: usersWarning,
                },
                {
                    linkTo: '/elders/publishers',
                    icon: 'publishers',
                    text: t('ZVESTOVATELIA'),
                },
                {
                    linkTo: '/elders/scheduleSettings',
                    icon: 'settings',
                    text: t('NASTAVENIA ROZPISU'),
                },
                {
                    linkTo: '/elders/cgSettings',
                    icon: 'settings',
                    text: t('NASTAVENIA ZBORU'),
                },
            ],
        },
    ];

    const MenuSectionHeading = ({ icon, label }) => (
        <SectionHeadingStyled>
            {!!icon && <i className={Icons[icon]} />}
            {!!label && t(label)}
        </SectionHeadingStyled>
    );

    const NavLinkCustomized = ({
        icon,
        text,
        to,
        showWarning,
        showEditLink,
    }) => (
        <NavLink
            id="mainMenu"
            onClick={resetScroll}
            className={({ isActive }) =>
                [showWarning && 'warningLink', isActive && 'activeLink'].join(
                    ' '
                )
            }
            to={to}
        >
            <Row>
                <i className={Icons[showWarning ? 'warning' : icon]} />
                {text}
            </Row>
            {showEditLink && (
                <NavLinkIconStyled to={`${to}/edit`}>
                    <i className={Icons['edit']} />
                </NavLinkIconStyled>
            )}
        </NavLink>
    );

    const renderSection = (sectionName) => {
        const selectedSection = menuSections.find(
            (s) => s.sectionName === sectionName
        );
        const itemsToShow = selectedSection.menuItems.filter(
            (item) => !item.disabled
        );
        if (!selectedSection.showSection || !itemsToShow.length) return null;
        return (
            <Box>
                {selectedSection.sectionHeading && (
                    <MenuSectionHeading
                        icon={selectedSection.icon}
                        label={selectedSection.sectionHeading}
                    />
                )}

                {itemsToShow.map((menuItem, i) => (
                    <NavLinkCustomized
                        key={`${menuItem.text}${i}`}
                        to={menuItem.linkTo}
                        icon={menuItem.icon}
                        text={menuItem.text}
                        showWarning={menuItem.showWarning}
                        showEditLink={menuItem.showEditLink}
                    />
                ))}
            </Box>
        );
    };

    const approvedSection = renderSection('approvedSection');
    const publisherSection = renderSection('publisherSection');
    const cgTasksSection = renderSection('cgTasks');
    const appointedSection = renderSection('appointed');
    const elderSection = renderSection('elder');
    const secretarySection = renderSection('secretary');
    const coordinatorSection = renderSection('administrator');

    return (
        <MenuStyled>
            {(!isLoggedIn || (isLoggedIn && !auth.authorization.length)) && (
                <MenuMessageStyled>
                    <i className={Icons['info']}></i>
                    {isLoggedIn
                        ? t(
                              'Zatiaľ máš obmedzený prístup, pretože tvoj účet ešte nebol schválený'
                          )
                        : t('Obsah len pre prihlásených')}
                </MenuMessageStyled>
            )}

            {approvedSection}

            {publisherSection}
            {isPublisher && cartsApproved && (
                <a
                    id="mainMenu"
                    href="https://jworg.sk/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <Row>
                        <i className={Icons['travel']}></i>
                        {t('STOJANY')}
                    </Row>
                </a>
            )}

            {cgTasksSection}
            {appointedSection}
            {elderSection}
            {secretarySection}
            {coordinatorSection}

            {!inSideDrawer && <VersionRow />}
        </MenuStyled>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Menu);

const MenuStyled = styled.nav`
    width: 220px;
    padding: 7px 0 15px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        display: none;
    }

    & a {
        width: 100%;
        min-height: 30px;
        line-height: 30px;
        text-decoration: none;
        color: ${(props) => props.theme.colors.black8};
        font-size: 1.2rem;
        display: block;
        padding-left: 10px;
        transition: color 0.2s;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
    }

    & a i {
        width: 1.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        transition: transform 0.2s;
    }
    & a:hover i {
        transform: scale(1.3);
    }
    & a:hover,
    & a:focus,
    & a.activeLink {
        color: #077669;
        background: #f0f3f38d;
        outline: none;
    }
    & a.activeLink {
        font-weight: 800;
    }
    & a.activeLink i {
        transform: scale(1.3);
    }
    & a.warningLink {
        background: #ff6b6b20;
        color: #ce2a11;
        font-weight: 800;
    }
    & a.warningLink i::first-of-type {
        color: #ce2a11;
    }
    @media ${mediaQueries.m} {
        width: 100%;
        & a#mainMenu {
            min-height: 42px;
            line-height: 42px;
        }
        & a#mainMenu:not(:last-child) {
            border-bottom: 1px solid #ebebeb;
        }
    }
`;

const NavLinkIconStyled = styled(NavLink)`
    color: #929292 !important;
    display: flex;
    width: auto !important;
    transition: background 0s !important;
    & i {
        transform: scale(1.3);
    }
    &:hover {
        background: #000000 !important;
        color: #ffffff !important;
    }
    @media ${mediaQueries.m} {
        & i {
            padding: 0 12px;
        }
    }
`;
const SectionHeadingStyled = styled(Row)`
    width: 100%;
    color: #ffffff;
    background: #0c9d8b;
    font-weight: 800;
    font-size: 1.2rem;
    margin: 10px 0 5px 0;
    padding: 5px 10px;
    & i {
        width: 1.4rem;
        margin-right: 7px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
`;

const MenuMessageStyled = styled.div`
    padding: 5px 10px;
    margin: 5px;
    font-size: 1.2rem;
    border-radius: ${(props) => props.theme.radius.s};
    background: ${(props) => props.theme.colors.warningLight};
    color: #000;
    & i {
        margin-right: 0.7rem;
    }
`;
