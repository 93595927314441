import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';
import { Icons } from 'common/Icons';

const Button = ({
    mini,
    disabled,
    type,
    icon,
    onClick,
    onMouseEnter,
    onMouseLeave,
    text,
    px,
    py,
    mx,
    my,
    height,
}) => {
    const colorName = !disabled && type ? type : 'disabled';

    return (
        <ButtonStyled
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            mini={mini}
            disabled={disabled}
            px={px}
            py={py}
            mx={mx}
            my={my}
            height={height}
            text={text}
            type={type}
            colorName={colorName}
        >
            {!!icon && <i className={Icons[icon]} />}
            {!!text && <Row>{text}</Row>}
        </ButtonStyled>
    );
};

export default Button;

const ButtonStyled = styled.button`
    height: ${(props) => props.height || 'auto'};
    width: fit-content;
    border: 0;
    outline: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: ${(props) => (props.noRadius ? 0 : `0.2rem`)};
    font-family: 'DMSans', calibri, tahoma;
    font-weight: 400;
    font-size: 1.2rem;
    transition: background 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    padding: ${(props) =>
        `${props.mini ? '2px' : props.py || '5px'} ${props.px || '10px'}`};
    margin: ${(props) =>
        `${props.my || '0px'} ${props.mini ? '5px' : props.mx || '0px'}`};

    @media ${mediaQueries.xs} {
        font-size: ${(props) => (!props.text ? '1.5rem' : '1.2rem')};
    }
    & i {
        margin-right: ${(props) => (props.text ? '.5rem' : '')};
        color: ${(props) => props.theme.colors.buttons[props.colorName]};
    }
    &:hover {
        background: #e2e2e2;
    }
    &:disabled {
        opacity: 0.5;
    }
`;
